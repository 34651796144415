import Head from "next/head";
import Image from "next/image";

export default function CommingSoon() {
  return (
    <>
      <Head>
        <title>Em breve Clinique</title>
      </Head>
      <div className="rbt-countdown-area rbt-maintenance-area rbt-section-gap vh-100 d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12">
              <div className="inner">
                <div className="section-title text-center">
                  {/* <p>
                    <span className="subtitle bg-black-opacity color-white">vem ai</span>
                  </p> */}
                  <Image src="/novos/img/logo-vertical-colorida.png" width={200} height={183} alt="Clinique" />
                  <p className="description has-medium-font-size mt--20 mb--0 color-black opacity-7">Excelência na formação em saúde</p>
                </div>
                {/* <div className="countdown-style-1 mt--50 justify-content-center">
                  <div className="countdown justify-content-center" data-date="2024-09-18">
                    <CountDown targetDate="2024-09-18" targetTime="12:00:00" />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

CommingSoon.displayName = "Empty";
